import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const RoundedTextField = styled(TextField)({
  marginTop: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "30px",
    },
  },
});

const BigButton = styled(Button)(({ theme }) => ({
  width: "90%",
  height: "40px",
  margin: "20px",
  borderRadius: "50px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  fontSize: "17px",
  color: "white",
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#686868",
  fontWeight: "bold",
  fontFamily: "fonts/Oxygen-Light, Segoe UI, Roboto",
  textTransform: "none",
  fontSize: "15px",
  marginTop: "20px",
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = styled(LinearProgressWithLabel)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#e7ebca",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#A0AD39",
  },
}));

const Uploader = ({ emailFromParams }) => {
  const [file, setFile] = useState();
  const [email, setEmail] = useState("");
  const [ccEmail, setccEmail] = useState("");
  const [message, setMessage] = useState();
  const [fileDesc, setFileDesc] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadId, setUploadId] = useState(null);
  const FUNCTIONS_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (emailFromParams) {
      setEmailWithChecks(emailFromParams);
    }
  }, [emailFromParams]);

  const handleClose = (event, reason) => {
    setMessage("");
    if (reason === "clickaway") {
      return;
    }
  };

  function cancelProcess() {
    setMessage("Upload Cancelled");
    setFile(null);
    setUploadProgress(null);
    setUploading(false);
  }

  function checkEmail(userEmail) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(userEmail);
  }

  function setEmailWithChecks(userEmail) {
    if (checkEmail(userEmail)) {
      setEmail(userEmail);
    }
  }

  const handleChange = (file) => {
    setMessage("");
    setUploadProgress(null);
    setEmailMessage("");
    setFile(file);
  };

  function openFileExplorer() {
    const input = document.createElement("input");
    input.type = "file";
    setUploadProgress(null);

    input.onchange = (e) => {
      const file = e.target.files[0];
      setMessage("");
      setEmailMessage("");
      setFile(file);
    };
    input.click();
  }

  async function uploadFile() {
    if (!email || !checkEmail(email)) {
      setEmailMessage("Please Enter A Valid Email");
      return;
    }

    if (!file) {
      setMessage("Please select a file first");
      return;
    }

    try {
      setUploading(true);

      // Get the upload URL
      const urlResponse = await fetch(`${FUNCTIONS_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
          email,
          ccEmail: ccEmail || undefined,
        }),
      });

      if (!urlResponse.ok) {
        const error = await urlResponse.json();
        throw new Error(error.message || "Failed to get upload URL");
      }

      const {
        uploadURL,
        fileKey,
        uploadId: newUploadId,
      } = await urlResponse.json();
      setUploadId(newUploadId);

      // Upload to S3
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentage = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentage);
        }
      });

      await new Promise((resolve, reject) => {
        xhr.open("PUT", uploadURL);
        xhr.setRequestHeader("Content-Type", file.type);

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error("Upload failed"));
          }
        };

        xhr.onerror = () => reject(new Error("Upload failed"));
        xhr.send(file);
      });

      // Confirm the upload
      const confirmResponse = await fetch("/api/confirmUpload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileKey,
          uploadId: newUploadId,
          email,
          ccEmail: ccEmail || undefined,
          fileDesc,
        }),
      });

      if (!confirmResponse.ok) {
        throw new Error("Failed to confirm upload");
      }

      setMessage("Upload Successful");
      setFile(null);
      setUploadProgress(100);
    } catch (error) {
      console.error("Upload error:", error);
      setMessage(error.message || "Upload Failed, Please try Again");
      setUploadProgress(0);
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <div className="itemContainer">
        {!file ? (
          <div style={{ marginTop: "80px", marginBottom: "60px" }}>
            <FileUploader handleChange={handleChange} name="file" />
          </div>
        ) : null}
        {!file && (
          <BigButton variant="contained" fullWidth onClick={openFileExplorer}>
            Add File
          </BigButton>
        )}
        {file && (
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                maxWidth: "100%",
                wordWrap: "break-word",
                marginTop: "40px",
              }}
            >
              File to Upload:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                maxWidth: "100%",
                wordWrap: "break-word",
                marginTop: "5px",
              }}
            >
              {file.name}
            </Typography>
          </div>
        )}
        {file && (
          <div>
            <RoundedTextField
              id="outlined-basic"
              label="Your Email Address*"
              error={!!emailMessage}
              fullWidth
              autoFocus={!emailFromParams}
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailMessage && (
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="left"
                sx={{
                  marginTop: "5px",
                  color: "#D32E2E",
                  fontFamily: "fonts/Oxygen-Light",
                }}
              >
                <ErrorOutlineIcon />
                <Typography
                  variant="subtitle2"
                  sx={{ fontFamily: "fonts/Oxygen-Light" }}
                >
                  {emailMessage}
                </Typography>
              </Stack>
            )}
            <RoundedTextField
              id="outlined-basic"
              label="CC Email (optional)"
              variant="outlined"
              fullWidth
              onChange={(e) => setccEmail(e.target.value)}
            />
            <RoundedTextField
              id="outlined-basic"
              label="Description of File (optional)"
              variant="outlined"
              fullWidth
              autoFocus={emailFromParams}
              onChange={(e) => setFileDesc(e.target.value)}
            />
          </div>
        )}
        {file && uploadProgress !== null && (
          <div style={{ marginTop: "20px" }}>
            <BorderLinearProgress
              variant="determinate"
              value={uploadProgress}
            />
          </div>
        )}
        {file && !uploading && (
          <BigButton
            variant="contained"
            className="button"
            fullWidth
            onClick={uploadFile}
          >
            Upload To Teldio
          </BigButton>
        )}
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={handleClose}
          sx={{ marginTop: "50px" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={message === "Upload Successful" ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>

      {file && !uploading ? (
        <CancelButton
          variant="text"
          className="cancelButton"
          fullWidth
          onClick={cancelProcess}
        >
          Cancel
        </CancelButton>
      ) : null}
    </>
  );
};

export default Uploader;
